import * as api from '../api';
import * as actionType from '../constants/actionTypes';

// Action creator

export const signIn = (formData,history) => async (dispatch) => {
    try {
        const {data} = await api.signIn(formData);
        dispatch({type:actionType.AUTH_LOADING});
             dispatch({type:actionType.AUTH,data});
        dispatch({type:actionType.AUTH_END_LOADING});
        history('/');
    } catch (error) {
        console.log(error.message);
        dispatch({type:actionType.AUTH_ERROR,error});
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: actionType.AUTH_LOADING }); // Indicate loading start
        const { data } = await api.forgotPassword(email); // Use API call from your `api` file
        dispatch({ type: actionType.FORGOT_PASSWORD_SUCCESS, payload: data }); // Dispatch success
        dispatch({ type: actionType.AUTH_END_LOADING }); // Indicate loading end
    } catch (error) {
        console.log(error.message);
        dispatch({ type: actionType.AUTH_ERROR, error });
        dispatch({ type: actionType.AUTH_END_LOADING }); // Indicate loading end even on error
    }
};

export const signUp = (formData,history) => async (dispatch) => {
    try {
        const {data} = await api.signUp(formData);
        dispatch({type:actionType.AUTH_LOADING});
             dispatch({type:actionType.LOGOUT});
        dispatch({type:actionType.AUTH_END_LOADING});
        // const signUpMSg = { msg:"" }
        dispatch({type:actionType.AUTH_SIGNUP_MSG});
        history('/login');
    } catch (error) {
        console.log(error.message);
        dispatch({type:actionType.AUTH_ERROR,error});
    }
}

export const resetPassword = ({ token, newPassword }) => async (dispatch) => {
    try {
      dispatch({ type: actionType.AUTH_LOADING }); // Indicate loading start
    const  data  = await  api.resetPassword(token, newPassword);
      // Dispatch success action with the response data
       dispatch({ type: actionType.RESET_PASSWORD_SUCCESS, payload: data });
      dispatch({ type: actionType.AUTH_END_LOADING }); // Indicate loading end
      return data;
    } catch (error) {
      console.error("Reset Password Error:", error.message);
  
      // Dispatch error action if the API call fails
      dispatch({
        type: actionType.AUTH_ERROR,
        payload: error.response?.data || error.message,
      });
  
      dispatch({ type: actionType.AUTH_END_LOADING }); // Indicate loading end even on error
    }
  };