import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { resetPassword } from '../actions/auth'; // Define this action in your Redux setup
import { useDispatch } from 'react-redux';
const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
   const dispatch = useDispatch();
  // Extract the token from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous messages
    setError("");
    setSuccess("");

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);

    try {

        const response = await dispatch(resetPassword({ token: token, newPassword: password }));
      setSuccess(response.data.message || "Password reset successfully.");
      setTimeout(() => navigate("/login"), 3000); // Redirect to login page after success
    } catch (err) {
      // setError(err.response?.data?.message || "Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="form_section my-9">
      <div className="container mx-auto">
        <div className="flex justify-center items-center">
          <div className="reset_form bg-mybluecolor p-6 shadow-md w-full max-w-md rounded-md">
            <h3 className="text-center text-white text-xl font-bold mb-6">Reset Password</h3>

            {/* Error and Success Messages */}
            {error && <p className="bg-red-600 text-white p-3 mb-5 mt-5 rounded">{error}</p>}
            {success && <p className="bg-green-600 text-white p-3 mb-5 mt-5 rounded">{success}</p>}

            <form onSubmit={handleSubmit}>
              {/* New Password Field */}
              <div className="form_row mb-4">
                <label htmlFor="password" className="block text-white text-sm font-medium mb-2">
                  New Password
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="Enter your new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="p-3 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-600 focus:outline-none"
                  required
                />
              </div>

              {/* Confirm Password Field */}
              <div className="form_row mb-4">
                <label htmlFor="confirmPassword" className="block text-white text-sm font-medium mb-2">
                  Confirm Password
                </label>
                <input
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="p-3 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-600 focus:outline-none"
                  required
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className={`w-full py-2 px-4 rounded-md text-white ${
                  loading ? "bg-gray-400 cursor-not-allowed" : "bg-myorangeolor hover:bg-blue-700 transition duration-300"
                }`}
                disabled={loading}
              >
                {loading ? "Resetting..." : "Reset Password"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
