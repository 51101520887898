import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes} from '@fortawesome/free-solid-svg-icons'
import { SERVER_URL } from '../../constants/actionTypes';
import { faMinus,faPlus } from '@fortawesome/free-solid-svg-icons';

export const CartProduct = ({page,item,handleDelete,count,updateItem}) => {
  const [qtyBtn,SetQtyBtn] = useState(item.qty);
console.log(item.product);
  // const updateCart = (item) => {
  //   let data = {
  //       product : product,
  //       qty : qtyBtn,
  //       _id : product._id
  //   };
  //   if(product.inventory >= qtyBtn ){
  //       if(!cart){
  //           localStorage.setItem('cart',JSON.stringify([data]));
  //           setCartCount(1);
  //       }else{
  //           const JsonCart = JSON.parse(cart);
  //           if(JsonCart.filter(e => e._id === product._id).length > 0){
  //               let newCart = JsonCart.map((c)=> {
  //                   if(c._id === product._id){
  //                       let newData = {
  //                           product : product,
  //                           qty : qtyBtn+c.qty,
  //                           _id : product._id   
  //                       }
  //                       return newData; 
  //                   }
  //                   return c;
  //               } );
  //               localStorage.setItem('cart',JSON.stringify(newCart));                    
  //           }else{
  //               setCartCount(cartCount+1);
  //               JsonCart.push(data);    
  //               localStorage.setItem('cart',JSON.stringify(JsonCart));                 
  //           }              
  //       }
  //       SetCartMsg('Product Added ');
  //       setOpenPopup(`${product.title} was successfully added to your cart.`);
    
  //   }else{
  //       SetCartMsg('You can not purchased more then available stock');    
  //   }       
  // }



  return (
    <>
    <div className='cart_item flex md:flex-row flex-col gap-5 pb-2 mb-3 md:border-0 md:border-b-2 border-2 border-[#eee]'>
        <div className={`basis-1/12 hidden ${page === 'checkout' ? 'md:hidden' : 'md:block'} `}>
          <h4 className='text-dark text-large font-primary font-semibold p-2'>{ count++ }</h4>
        </div>
        <div className='basis-6/12'>

          <div className='flex md:flex-row flex-col gap-5'>
              <div className='basis-1/12 md:text-left text-center relative'>
                <img src={item.product.thumbnail !== '' ? SERVER_URL+item.product.thumbnail : require('../../Images/pro_img1.png')} alt="Logo" className='md:inline-block block full w-full'/>
                  <button onClick={() => handleDelete(item._id,qtyBtn)} className={`md:hidden block absolute top-0 -right-0 bg-red-500 p-2 ${page === 'checkout' ? 'hidden' : 'block'} `}><FontAwesomeIcon icon={faTimes} className="text-large text-white"/></button>
              </div>
              <div className='basis-11/12'>
                <h4 className = 'text-dark text-2xl md:text-left text-center font-primary font-semibold'>{ item.product.title }</h4>
                {/* <div className='text-dark text-large'>Quantity: </div> */}
                <div className={`qty_list ${page === 'checkout' ? 'md:hidden' : ''}`}>
                      <ul className='qty_list flex flex-row my-4'>
                          <li>
                             <button className='cartQtyBtn text-sm text-xl px-3 py-2 border-2 bg-white border-[#fff] border-r-0' onClick={() => {if(qtyBtn>1){SetQtyBtn(qtyBtn - 1)}}}>
                                 <FontAwesomeIcon icon={faMinus}/>
                             </button>
                          </li>
                          <li>
                              <input type="number" name="pro_qty_number" value={qtyBtn}
                               onChange={ 
                                (e) => { 
                                    let num = e.target.value;
                                    num < 1 ? SetQtyBtn(1) : SetQtyBtn(num); 
                                } 
                               }
                              className="p-1 placeholder-black text-white relative bg-myorangeolor text-sm 
                               w-14 font-normal border-2 border-myorangeolor min-h-[40px] text-center"
                              />
                          </li>
                          <li>
                              <button className='cartQtyBtn text-sm text-xl px-3 py-2 border-2 bg-white border-[#fff] border-l-0' onClick={() => {SetQtyBtn(qtyBtn + 1)}}>
                                  <FontAwesomeIcon icon={faPlus}/>
                              </button>
                          </li>
                          <li>
                            <button className='updateProductCart' onClick={()=> updateItem(item._id,qtyBtn)} >Update Product</button>
                          </li>
                      </ul>                      
                    </div>

              </div>
          </div>
        </div>
         <div className='basis-1/12'>
          <h4 className='text-dark text-large font-primary font-semibold md:p-2 p-0 md:text-left text-center '>{qtyBtn}</h4>
        </div>
        <div className='basis-1/12'>
          <h4 className='text-dark text-large font-primary font-semibold md:p-2 p-0 md:text-left text-center '>${(item.product.price)}</h4>
        </div>
        <div className={`${page === 'checkout' ? 'basis-1/6' : 'basis-1/12'}`}>
          <h4 className='text-dark text-large font-primary font-semibold md:p-2 p-0 md:text-left text-center '>$ {(item.product.price * item.qty).toFixed(2)}</h4>
        </div>
        <div className={`basis-1/12 hidden ${page === 'checkout' ? 'md:hidden' : 'md:block'} `}>
          <button onClick={() => handleDelete(item._id)} className=''><FontAwesomeIcon icon={faTimes} className="text-large text-myorangeolor"/></button>
        </div>
    </div>
    </>
  )
}
